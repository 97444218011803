<template>
	<div v-if="order!=null">
		<a-spin :spinning="loading">
			<div class="flex space alcenter">
				<div class="ft20 cl-black cl-main ftw500">预约订单详情</div>
				<a-button @click="$router.go(-1)">返回</a-button>
			</div>
			<div class="mt20">
				<div class="bg-w pd40" style="min-height: 800px;">

					<div class="">
						<div class="flex alcenter">
							<div class="">
								<div class="flex alcenter">
									<div class="flex alcenter">
										<div class="ft14 ftw400 cl-info">订单编号：</div>
										<div class="ft14 ftw600 cl-main">{{order.appointment_id}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info">下单时间：</div>
										<div class="ft14 ftw600 cl-main">{{order.add_time_format}}</div>
									</div>

									<div class="flex alcenter ml40">
										<div class="ft14 ftw400 cl-info text-over4">预约时间：</div>
										<div class="flex alcenter">
											<div class="ft14 ftw600 cl-main text-over4">{{order.day}}</div>
											<div class="ft14 ftw600 cl-theme text-over4">{{order.start_time}}-{{order.end_time}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="mall-goods_order-detail-info">
							<div class="flex ">
								<div class="pl40" style="width: 33%;border-right: 1px solid #EBEDF5;">
									<div class="ft14 ftw500 cl-main">订单状态</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==1">待确认</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==3">待服务</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==4">服务中</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==8">已完成</div>
									<div class="ft20 ftw600 cl-theme mt16" v-if="order.status==-1">已取消</div>
									<div class="mt16 flex alcenter" v-if="order.status==1">
										<div class="button-deliver "  @click="sureAppointAct()">确认预约</div>
										<div class="button-cancel ml20"  @click="cancelAppointAct()">取消预约</div>
									</div>

									<div class="mt16 flex alcenter" v-if="order.status==3">
										<div class="button-deliver " @click="startServiceAct()">开始服务</div>
										<div class="button-cancel ml20"  @click="cancelAppointAct()">取消预约</div>
									</div>
									<div class="button-deliver mt16"  v-if="order.status==4" @click="completeAppointAct()">完成服务</div>
								</div>
								<div class="" style="width: 33%;">
									<div class="flex" style="border-right: 1px solid #EBEDF5; ">
										<div class="ml40 text-over4">
											<div class="ft14 ftw500 cl-main text-over4">付款信息</div>
											<div class="mt16">
												<div class="flex">
													<div>
														<div class="flex alcenter ">
															<div class="ft14 ftw400 cl-info text-over4">支付方式：</div>
															<div class="ft14 ftw600 cl-main text-over4" >
																{{order.pay_type_mean}}
															</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 ftw400 cl-info text-over4">支付时间：</div>
															<div class="ft14 ftw600 cl-main text-over4" >
																{{order.pay_time_format}}
															</div>
														</div>
														<div class="flex alcenter mt10">
															<div class="ft14 ftw400 cl-info text-over4">实付金额：</div>
															<div class="flex alcenter" v-if="order.is_paid==1">
																<div class="ft14 ftw600 cl-main text-over4" >¥{{order.need_pay}}</div>
																<div class="ml8 cl-notice">原价：</div>
																<div class=" cl-notice text-line">{{order.total_price}}</div>
																<div class="ml8 flex alcenter" v-if="order.added_price>0">
																	<div class=" cl-info">增值价格：</div>
																	<div class=" cl-red ">+{{order.added_price}}</div>
																</div>
															</div>

															<div class="flex alcenter" v-else>
																<div class="ft14 ftw600 cl-main text-over4" >未支付</div>
																<div class="ml8 cl-notice">原价：</div>
																<div class=" cl-notice text-line">{{order.total_price}}</div>
															</div>


														</div>
														<div v-if="order.added_price_info">
															<span class="ft14 ftw400 cl-info">增值说明:</span>
															<span class="cl-notice ft14">{{order.added_price_info}}</span>
														</div>


													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="" style="width: 33%;">
									<div class="ml40">
										<div class="ft14 ftw500 cl-main">客户信息</div>
										<div class="mt16">
											<div class="flex">
												<div>
													<div class="flex alcenter">
														<div class="ft14 ftw400 cl-info text-over4">客户姓名：</div>
														<div class="ft14 ftw600 cl-main text-over4">
															{{order.member_name}}</div>
													</div>
													<div class="flex alcenter mt10">
														<div class="ft14 ftw400 cl-info text-over4">手机号码：</div>
														<div class="ft14 ftw600 cl-main text-over4">
															{{order.member_mobile}}</div>
													</div>
													<div class="flex  mt10" >
														<div class="ft14 ftw400 cl-info text-over4">上门地址：</div>
														<div style="width: calc(100% - 120px);">
															<div class="ft14 ftw600 cl-main">
																{{order.contact_name}} {{order.contact_mobile}}
															</div>
															<div class="ft14 ftw600 cl-main">
																{{order.contact_address}}
															</div>
														</div>

													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt30" v-if="this.order.extra">
						<div class="mall-goods_order-detail-info">
							<div class="pl40" >
								<div class="ft14 ftw500 cl-main">其他信息</div>
								<div class="flex alcenter mt16">
									<div class="flex alcenter" :class="{ml40 : index>0}" v-for="(item,index) in this.order.extra">
										<div class="ft14 ftw400 cl-info">{{item.valueName}}：</div>
										<div class="ft14 ftw600 cl-main" v-if="item.key=='checkbox'">{{item.value.join(',')}}</div>
										<div class="ft14 ftw600 cl-main" v-else-if="item.key=='select'">{{item.datas[item.value].name}}</div>
										<div class="ft14 ftw600 cl-main" v-else-if="item.key=='switch'">{{item.value ? '是' : '否'}}</div>
										<div class="ft14 ftw600 cl-main" v-else>{{item.value}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="mt30">
						<div class="flex wrap">
							<div class="mall-goods_order-detail-subcard-item" >
								<div class="flex  alcenter space">
									<div class="flex alcenter">
										<img v-if="order.project_cover_img != null" :src="order.project_cover_img" />
										<div class="ml10">
											<div class="ft14 ftw600 cl-main" style="width: 500px;">
												{{order.project_name}}
											</div>
											<div class="ft12 ftw400 cl-main mt5" >
												¥{{order.total_price}}
											</div>
											<div class="ft12 ftw400 cl-notice mt5">{{order.project_sku_name}}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="flex end mt10">
						<div class="flex" v-if="order.is_paid==1">
							<div class="ft12 ftw400 cl-info">
								<div class="flex end">合计</div>
								<div class="flex end mt8" v-if="order.added_price>0">增值价格</div>
								<div class="flex end mt8" v-if="order.coupon_money>0">优惠券抵扣</div>
								<div class="flex end mt8" v-if="order.integral_balance>0">积分抵扣</div>
								<div class="flex end mt8" v-if="order.pay_type=='offline' && order.youhui_balance > 0">优惠金额</div>
								<div class="flex end mt8 ft14 ftw500 cl-black">订单金额</div>
							</div>

							<div class="ml60 ft12 ftw400 cl-info">
								<div class="flex end">¥{{order.total_price}}</div>
								<div class="flex end mt8">+¥{{order.added_price}}</div>
								<div class="flex end mt8" v-if="order.coupon_money>0">-¥{{order.coupon_money}}</div>
								<div class="flex end mt8" v-if="order.integral_balance>0">-¥{{order.integral_balance}}
								</div>
								<div class="flex end mt8" v-if="order.pay_type=='offline' && order.youhui_balance > 0">-¥{{order.youhui_balance}}</div>
								<div class="flex end mt8 ft14 ftw500 cl-black">¥{{orderPrice}}</div>
							</div>
						</div>

						<div class="flex ft14 ftw600 cl-main" v-else>
							未支付
						</div>
					</div>

					<div v-if="cancelAppointVisible">
						<cancel-appoint :appointment_id="order.appointment_id" :visible="cancelAppointVisible" @cancel="cancelCancelAppoint" @ok="okCancelAppoint"></cancel-appoint>
					</div>
					<div v-if="distributionServicemanVisible">
						<distribution-serviceman :appointment_id="order.appointment_id" :visible="distributionServicemanVisible" @cancel="cancelDistributionServiceman"
						@ok="okDistributionServiceman"></distribution-serviceman>
					</div>
					<div v-if="completeAppointVisible">
						<complete-appoint :order="order" :visible="completeAppointVisible" @cancel="cancelCompleteAppoint" @ok="okCompleteAppoint"></complete-appoint>
					</div>
				</div>
			</div>
		</a-spin>
	</div>

</template>

<script>
	import cancelAppoint from './components/order/modal/detail/modal/cancelAppoint.vue';
	import completeAppoint from './components/order/modal/completeAppoint.vue';
	import distributionServiceman from './components/order/modal/distributionServiceman.vue';
	export default {
		components:{
			cancelAppoint,
			distributionServiceman,
			completeAppoint,
		},
		data() {
			return {
				loading: false,
				cancelAppointVisible:false,
				distributionServicemanVisible:false,
				completeAppointVisible:false,
				appointment_id: 0,
				order: null
			}
		},
		created() {
			if (!this.$route.query.id) {
				this.appointment_id = 0;
			} else {
				this.appointment_id = parseInt(this.$route.query.id);
			}
			this.loaddata();
		},
		computed:{
			orderPrice(){
				return parseFloat(this.order.need_pay)+parseFloat(this.order.added_price)
			}
		},
		methods: {
			loaddata() {
				if (this.loading == true) return;
				this.loading = true;
				this.$http.api('platform/getAppointmentDetail', {
					appointment_id: this.appointment_id
				}).then(res => {
					this.order = res.detail;
					this.loading = false;
				}).catch(res => {
					console.log(res);
					this.loading = false;
					this.$router.go(-1);
				})
			},

			sureAppointAct(){
				if(this.order.serviceman_id!=0){
					this.$confirm({
						title:'确认这个预约吗？',
						okText:"确定",
						okType:"danger",
						cancelText:"取消",
						onOk : ()=>{
							return new Promise((resolve,reject)=>{
								this.$http.api('platform/sureAppointment',{
									appointment_id:this.order.appointment_id,
								}).then(res=>{
									this.$message.success('确认成功');
									this.loaddata();
								}).catch(res=>{
									console.log(res);
								}).finally(()=>{
									resolve();
								})
							})
						}
					})
				}else{
					this.distributionServicemanAct();
				}
			},

			distributionServicemanAct(){
				this.distributionServicemanVisible=true;
			},
			cancelDistributionServiceman(){
				this.distributionServicemanVisible=false;
			},
			okDistributionServiceman(){
				this.distributionServicemanVisible=false;
				this.loaddata();
			},

			startServiceAct(){
				this.$confirm({
					title:'确认开始服务了吗？',
					okText:"确定",
					okType:"danger",
					cancelText:"取消",
					onOk : ()=>{
						return new Promise((resolve,reject)=>{
							this.$http.api('platform/startService',{
								appointment_id:this.order.appointment_id,
							}).then(res=>{
								this.$message.success('操作成功');
								this.loaddata();
							}).catch(res=>{
								console.log(res);
							}).finally(()=>{
								resolve();
							})
						})
					}
				})
			},

			//完成服务
			completeAppointAct(){

				this.completeAppointVisible=true;
			},
			cancelCompleteAppoint(){
				this.completeAppointVisible=false;
			},
			okCompleteAppoint(){
				this.completeAppointVisible=false;
				this.loaddata();
			},

			//取消预约
			cancelAppointAct(){
				this.cancelAppointVisible=true;
			},
			cancelCancelAppoint(){
				this.cancelAppointVisible=false;
			},
			okCancelAppoint(){
				this.cancelAppointVisible=false;
				this.loaddata();
			},

		}
	}
</script>

<style>
	.mall-goods_order-detail-tag {
		padding: 1px 8px;
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #FFFFFF;
		line-height: 16px;
	}

	.mall-goods_order-detail.tag {
		padding: 1px 4px;
		background: rgba(71, 114, 255, 0.1);
		border-radius: 2px;

		font-size: 12px;
		font-weight: 500;
		color: #4772FF;
	}


	.mall-goods_order-detail-info {
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		padding: 24px 0px;
	}

	.mall-goods_order-detail-subcard-item {
		width: 100%;
		height: 120px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		margin-bottom: 20px;
		padding: 20px;
	}

	.mall-goods_order-detail-subcard-item img {
		width: 106px;
		height: 80px;
		border-radius: 4px;
	}

	.button-deliver {
		width: 96px;
		height: 40px;
		background: #4772FF;
		border-radius: 4px;
		font-size: 16px;
		font-weight: 600;
		color: #FFFFFF;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}

	.button-cancel{
		width: 96px;
		height: 40px;
		background: #FFFFFF;
		border-radius: 4px;
		border: 1px solid #000000;
		font-size: 16px;
		font-weight: 600;
		color: #000000;
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}
</style>
